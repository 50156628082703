<script setup lang="ts">
import SideBarItem from "./SideBarItem.vue";
import HelpCenter from "@/components/dashboard/support/HelpCenter.vue";
import AppLogo from "@/layouts/partials/marketing/AppLogo.vue";
import type { NavigationItems } from "@/types";

interface Props {
  navigation: NavigationItems;
  subNavigation: NavigationItems;
}

defineProps<Props>();
</script>

<template>
  <div class="hidden xl:fixed xl:inset-y-0 xl:z-30 xl:flex xl:w-72 xl:flex-col">
    <div class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-light-gray dark:bg-black-gray bg-white px-6 py-4">
      <div class="flex h-8 shrink-0 items-center">
        <AppLogo />
      </div>
      <nav class="flex flex-1 flex-col -mx-2">
        <ul role="list" class="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" class="space-y-2">
              <template v-for="item in navigation" :key="item.name">
                <SideBarItem :item="item" />
              </template>
            </ul>
          </li>
          <div class="mt-auto">
            <ul role="list" class="space-y-2">
              <template v-for="item in subNavigation" :key="item.name">
                <SideBarItem :item="item" />
              </template>
            </ul>
          </div>
        </ul>
        <HelpCenter class="mt-2" />
      </nav>
    </div>
  </div>
</template>
